import {
    UPDATE_DOCS_CAT_LIST,
    UPDATE_DOCS_CAT_LOAD_STATUS
} from 'Store/DocumentsCatagory/DocumentsCatagory.action';

/** @namespace Store/DocumentsCatagory/Reducer/getInitialState */
export const getInitialState = () => ({
    categories: [],
    count: 0,
    isLoading: true
});

/** @namespace Store/DocumentsCatagory/Reducer */
export const DocumentsCatagoryReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_DOCS_CAT_LIST:
        const { documentsCategoryList: { items, count } } = action.data;

        return {
            ...state,
            categories: items,
            count
        };
    case UPDATE_DOCS_CAT_LOAD_STATUS:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    default:
        return state;
    }
};

export default DocumentsCatagoryReducer;
