/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Link from 'Component/Link';
import { DeviceType } from 'Type/Device';

import './Footer.style';

/**
 * Page footer
 * @class Footer
 * @namespace Component/Footer/Component
 */
export class Footer extends PureComponent {
    static propTypes = {
        isVisibleOnMobile: PropTypes.bool,
        device: DeviceType.isRequired,
        footerContent: PropTypes.array.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isVisibleOnMobile: true
    };

    renderLink = ({ href = '/', title, icon }, i) => (
        <Link
          block="Footer"
          elem="NavItem"
          to={ href }
          key={ i }
          aria-label={ title }
        >
            { icon && <span className={ `icon icon-${icon}` } /> }
            <span>{ title }</span>
        </Link>
    );

    renderNavItems() {
        const { isLoading, footerContent } = this.props;

        if (!isLoading && footerContent.length) {
            return (
                <ContentWrapper
                  isNotSection
                  wrapperMix={ { block: 'Footer', elem: 'Nav' } }
                  label=""
                >
                    { footerContent.map(this.renderLink) }
                </ContentWrapper>
            );
        }

        return null;
    }

    render() {
        const { isVisibleOnMobile, device } = this.props;

        if (!isVisibleOnMobile && device.isMobile) {
            return null;
        }

        return (
            <footer block="Footer" aria-label="Footer">
                <div block="Footer" elem="Content">
                    { this.renderNavItems() }
                </div>
            </footer>
        );
    }
}

export default Footer;
