/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isSignedIn } from 'Util/Auth';
import NavigationAbstract from 'Component/NavigationAbstract/NavigationAbstract.component';
import { DeviceType } from 'Type/Device';

import {
    ACCOUNT_TAB, CATALOGUE_TAB, HOME_TAB, WARRANTY_TAB, ELEARNING_TAB
} from './NavigationTabs.config';

import './NavigationTabs.style';

/** @namespace Component/NavigationTabs/Component */
export class NavigationTabs extends NavigationAbstract {
    static propTypes = {
        device: DeviceType.isRequired
    };

    defaultStateName = HOME_TAB;

    stateMap = {
        [HOME_TAB]: {
            home: true
        },
        [CATALOGUE_TAB]: {
            catalogue: true
        },
        [ACCOUNT_TAB]: {
            account: true
        },
        [WARRANTY_TAB]: {
            warranty: true
        },
        [ELEARNING_TAB]: {
            elearning: true
        }
    };

    renderMap = {
        home: this.renderHomeButton.bind(this),
        catalogue: this.renderCatalogueButton.bind(this),
        account: this.renderAccountButton.bind(this),
        warranty: this.renderWarrantyButton.bind(this),
        elearning: this.renderElearningButton.bind(this)
    };

    renderHomeButton(isActive = false) {
        const { onHomeButtonClick } = this.props;

        return (
            <button
              key="home"
              block="NavigationTabs"
              elem="Button"
              aria-label="Home"
              onClick={ onHomeButtonClick }
              mods={ { isActive, isVisible: true } }
            >
                <div
                  block="NavigationTabs"
                  elem="Icon"
                  className="icon-home"
                />
                <div
                  block="NavigationTabs"
                  elem="Label"
                >
                    { __('Home') }
                </div>
            </button>
        );
    }

    renderCatalogueButton(isActive = false) {
        const { onCatalogueButtonClick } = this.props;

        return (
            <button
              key="catalogue"
              block="NavigationTabs"
              elem="Button"
              aria-label="Catalogue"
              onClick={ onCatalogueButtonClick }
              mods={ { isActive, isVisible: true } }
            >
                <div
                  block="NavigationTabs"
                  elem="Icon"
                  className="icon-collection"
                />
                <div
                  block="NavigationTabs"
                  elem="Label"
                >
                    { __('Catalogue') }
                </div>
            </button>
        );
    }

    renderAccountButton(isActive = false) {
        const { onMyAccountButtonClick } = this.props;

        return (
            <button
              key="account"
              block="NavigationTabs"
              elem="Button"
              onClick={ onMyAccountButtonClick }
              aria-label="Open my account"
              mods={ { isActive, isVisible: true } }
            >
                <div
                  block="NavigationTabs"
                  elem="Icon"
                  className="icon-account"
                />
                <div
                  block="NavigationTabs"
                  elem="Label"
                >
                    { __('My account') }
                </div>
            </button>
        );
    }

    renderWarrantyButton(isActive = false) {
        const { onWarrantyButtonClick } = this.props;

        return (
            <button
              key="warranty"
              block="NavigationTabs"
              elem="Button"
              aria-label="Warranty"
              onClick={ onWarrantyButtonClick }
              mods={ { isActive, isVisible: true } }
            >
                <div
                  block="NavigationTabs"
                  elem="Icon"
                  className="icon-warranty"
                />
                <div
                  block="NavigationTabs"
                  elem="Label"
                >
                    { __('Warranty') }
                </div>
            </button>
        );
    }

    renderElearningButton(isActive = false) {
        const { onElearningButtonClick } = this.props;

        return (
            <button
              key="elearning"
              block="NavigationTabs"
              elem="Button"
              aria-label="E-Learning"
              onClick={ onElearningButtonClick }
              mods={ { isActive, isVisible: true } }
            >
                <div
                  block="NavigationTabs"
                  elem="Icon"
                  className="icon-book"
                />
                <div
                  block="NavigationTabs"
                  elem="Label"
                >
                    { __('E-Learning') }
                </div>
            </button>
        );
    }

    render() {
        const { navigationState: { isHidden }, device } = this.props;

        if (!device.isMobile || !isSignedIn()) {
            return null;
        }

        return (
            <footer
              block="NavigationTabs"
              mods={ { isHidden } }
              mix={ { block: 'FixedElement', elem: 'Bottom' } }
            >
                <nav block="NavigationTabs" elem="Nav">
                    { this.renderNavigationState() }
                </nav>
            </footer>
        );
    }
}

export default NavigationTabs;
