export const UPDATE_FAV_POST_LIST_LOAD_STATUS = 'UPDATE_FAV_POST_LIST_LOAD_STATUS';
export const CLEAR_FAV_LIST = 'CLEAR_FAV_LIST';
export const ADD_FAV = 'ADD_FAV';
export const REMOVE_FAV = 'REMOVE_FAV';

/** @namespace Store/BcsPostFav/Action/addPostFavToList */
export const addPostFavToList = (id) => ({
    type: ADD_FAV,
    id
});

/** @namespace Store/BcsPostFav/Action/removePostFavFromList */
export const removePostFavFromList = (id) => ({
    type: REMOVE_FAV,
    id
});

/** @namespace Store/BcsPostFav/Action/resetPostFavList */
export const resetPostFavList = () => ({
    type: CLEAR_FAV_LIST
});

/**
 * Update loading status
 * @param {Boolean} status Loading indication boolean
 * @return {void}
 * @namespace Store/BcsPostFav/Action/updatePostFavListLoadStatus
 */
export const updatePostFavListLoadStatus = (status) => ({
    type: UPDATE_FAV_POST_LIST_LOAD_STATUS,
    isLoading: status
});
