/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import gsap from 'gsap';

import Loader from 'Component/Loader';
import { ProductType } from 'Type/ProductList';
import { isSignedIn } from 'Util/Auth';

import './ProductWishlistButton.style';

/** @namespace Component/ProductWishlistButton/Component */
export class ProductWishlistButton extends PureComponent {
    static propTypes = {
        isReady: PropTypes.bool,
        isLoading: PropTypes.bool,
        quantity: PropTypes.number,
        isDisabled: PropTypes.bool,
        isInWishlist: PropTypes.bool,
        product: ProductType.isRequired,
        addToWishlist: PropTypes.func.isRequired,
        removeFromWishlist: PropTypes.func.isRequired,
        mix: PropTypes.shape({ block: PropTypes.string, elem: PropTypes.string, mod: PropTypes.string })
    };

    static defaultProps = {
        mix: {},
        quantity: 1,
        isReady: true,
        isLoading: false,
        isDisabled: false,
        isInWishlist: false
    };

    getTitle = () => {
        const { isInWishlist, isReady } = this.props;

        if (!isSignedIn()) {
            return __('Please sign in first!');
        }

        if (!isReady) {
            return __('Please select variant first!');
        }

        if (isInWishlist) {
            return __('Remove from Wishlist');
        }

        return __('Add to Wishlist');
    };

    closeWishlistAfterDelay() {
        const { isLoading } = this.props;

        if (isLoading) {
            return this.closeWishlistAfterDelay();
        }

        const delay = 3000;

        return setTimeout(() => {
            const wishListBar = document.querySelector('.Header-Wishlist');
            const wishListOverlay = document.querySelector('.Header-WishlistOverlay');
            if (wishListBar && wishListOverlay) {
                gsap.to(wishListBar, { x: '100%', duration: 0.5 });
                gsap.to(wishListOverlay, {
                    autoAlpha: 0,
                    duration: 0.5,
                    onComplete: () => {
                        wishListOverlay.classList.remove('visible')
                    }
                });
            }
        }, delay);
    }

    onClick = () => {
        const {
            product,
            quantity,
            isInWishlist,
            addToWishlist,
            removeFromWishlist
        } = this.props;

        if (!isInWishlist) {
            this.closeWishlistAfterDelay();
            return addToWishlist(product, quantity);
        }

        return removeFromWishlist(product, quantity);
    };

    renderButton() {
        const {
            isInWishlist,
            isDisabled,
            isLoading,
            mix
        } = this.props;

        return (
            <button
              block="ProductWishlistButton"
              elem="Button"
              mods={ { isInWishlist, isDisabled, isLoading } }
              mix={ { block: 'Button', mods: { isHollow: !isInWishlist }, mix } }
              title={ this.getTitle() }
              onClick={ this.onClick }
            >
                { isInWishlist
                    ? <span block="icon-favoris-fill" />
                    : <span block="icon-favoris-empty" /> }
                { isInWishlist
                    ? <span block="label-favoris">{ __('Remove from Wishlist') }</span>
                    : <span block="label-favoris">{ __('Add to wishlist') }</span> }
            </button>
        );
    }

    renderLoader() {
        const { isLoading } = this.props;

        return (
            <Loader isLoading={ isLoading } />
        );
    }

    renderContent() {
        return (
            <div block="ProductWishlistButton">
                { this.renderButton() }
            </div>
        );
    }

    render() {
        const { product: { id } = {} } = this.props;

        if (id !== -1) {
            return this.renderContent();
        }

        return null;
    }
}

export default ProductWishlistButton;
