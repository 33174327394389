import { MATRIX_OPTIONS_LIMIT } from 'src/constants';

export const isMatrixEnabled = (configurable_options) => {
    if (configurable_options === undefined) {
        return false;
    }
    if (Object.keys(configurable_options).length === 0) {
        return false;
    }

    const size = Object.keys(configurable_options).length;
    return size < MATRIX_OPTIONS_LIMIT;
};

export const isSimpleMatrix = (configurable_options) => Object.keys(configurable_options).length === 1;

export const allValuesAreZero = (data) => {
    if (Object.keys(data).length === 0) {
        return true;
    }
    const isSimpleValues = Object.values(data.values).every((value) => typeof value === 'number');

    if (isSimpleValues) {
        return Object.values(data.values).every((value) => value === 0);
    }

    const allValues = Object.values(data.values).flatMap((obj) => Object.values(obj));
    return allValues.every((value) => Number(value) === 0);
};

export const constructQuantities = (matrixArray, configurableOptions) => {
    const { colAttribute, rowAttribute, values } = matrixArray.data;
    const isSimple = isSimpleMatrix(configurableOptions);

    const result = {
        colAttribute: { id: colAttribute.id, code: colAttribute.code },
        values: Object.values(values).reduce((acc, item) => {
            if (isSimple) {
                acc[item.value] = 0;
            } else {
                acc[item.value] = Object.fromEntries(
                    Object.keys(item.colors).map((colorKey) => [colorKey, 0])
                );
            }

            return acc;
        }, {})
    };

    if (!isSimple) {
        result.rowAttribute = { id: rowAttribute.id, code: rowAttribute.code };
    }

    return result;
};

export const isAddToCartEnabled = (sellInConfig, product, customer) => {
    const { categories } = product;
    if (typeof sellInConfig.isEnabled === 'undefined') {
        return null;
    }
    if (typeof categories === 'undefined') {
        return null;
    }

    // website level
    if (!Number(sellInConfig.isEnabled)) {
        return false;
    }

    // category level
    const hasDisabledCategory = categories.find((item) => item.is_sellin_disabled === true);
    if (hasDisabledCategory) {
        if (hasDisabledCategory.sellin_allowed_customer_groups.includes(customer.group_id)) {
            return true;
        }
        if (hasDisabledCategory.sellin_allowed_ids_shops.includes(customer.id_boutique)) {
            return true;
        }

        return false;
    }

    // product level
    if (product.disable_sellin) {
        return false;
    }

    return true;
};
