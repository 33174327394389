export const UPDATE_VOLLETO_TOKEN = 'UPDATE_VOLLETO_TOKEN';
export const RESET_VOLLETO_TOKEN = 'RESET_VOLLETO_TOKEN';
export const UPDATE_VOLLETO_LOAD_STATUS = 'UPDATE_VOLLETO_LOAD_STATUS';

/** @namespace Store/Volleto/Action/updateVolletoToken */
export const updateVolletoToken = (data) => ({
    type: UPDATE_VOLLETO_TOKEN,
    data
});

/** @namespace Store/Volleto/Action/resetVolletoToken */
export const resetVolletoToken = () => ({
    type: RESET_VOLLETO_TOKEN
});

/**
 * Update loading status
 * @param {Boolean} status Loading indication boolean
 * @return {void}
 * @namespace Store/Volleto/Action/updateVolletoLoadStatus
 */
export const updateVolletoLoadStatus = (status) => ({
    type: UPDATE_VOLLETO_LOAD_STATUS,
    isLoading: status
});
