/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
/* eslint-disable fp/no-delete */
/** @namespace Component/FieldInput/Component */
export class FieldInput extends PureComponent {
    static propTypes = {
        formRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ])
    };

    static defaultProps = {
        formRef: () => {}
    };

    render() {
        const {
            formRef,
            ...validProps
        } = this.props;

        const props = { ...validProps }

        // Removed un-valid HTML props
        delete props.isControlled
        delete props.formRefMap
        delete props.validationStatus
        delete props.handleChange
        delete props.onChangeCheckbox
        delete props.onKeyEnterDown
        delete props.autocomplete

        return (
            <input
              block="FieldInput"
              ref={ formRef }
              { ...props }
            />
        );
    }
}

export default FieldInput;
