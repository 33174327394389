/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
export {
    default, WYSIWYG_MEDIA, PRODUCT_MEDIA, CATEGORY_MEDIA, SEARCH_MEDIA
} from './Media';
