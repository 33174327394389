/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const OPEN_MENU = 'OPEN_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const SET_DEFAULT_ITEMS = 'SET_DEFAULT_ITEMS';
export const SET_CURRENT_ITEMS = 'SET_CURRENT_ITEMS';

/** @namespace Store/MobileMenu/Action/openMenu */
export const openMenu = () => ({
    type: OPEN_MENU
});

/** @namespace Store/MobileMenu/Action/closeMenu */
export const closeMenu = () => ({
    type: CLOSE_MENU
});

/** @namespace Store/MobileMenu/Action/toggleMenu */
export const toggleMenu = () => ({
    type: TOGGLE_MENU
});

/** @namespace Store/MobileMenu/Action/setDefaultItems */
export const setDefaultItems = (defaultItems) => ({
    type: SET_DEFAULT_ITEMS,
    defaultItems
});

/** @namespace Store/MobileMenu/Action/setCurrentItems */
export const setCurrentItems = (currentItems) => ({
    type: SET_CURRENT_ITEMS,
    currentItems
});
