/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    OPEN_MENU,
    CLOSE_MENU,
    TOGGLE_MENU,
    SET_DEFAULT_ITEMS,
    SET_CURRENT_ITEMS
} from './MobileMenu.action';

/** @namespace Store/MobileMenu/Reducer/getInitialState */
export const getInitialState = () => ({
    isOpen: false,
    defaultItems: [],
    currentItems: []
});

/** @namespace Store/MobileMenu/Reducer */
export const MobileMenuReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case OPEN_MENU:
        return { ...state, isOpen: true };
    case CLOSE_MENU:
        return { ...state, isOpen: false };
    case TOGGLE_MENU:
        return { ...state, isOpen: !state.isOpen };
    case SET_DEFAULT_ITEMS:
        const { defaultItems } = action;
        return { ...state, defaultItems };
    case SET_CURRENT_ITEMS:
        const { currentItems } = action;
        return { ...state, currentItems };
    default:
        return state;
    }
};

export default MobileMenuReducer;
