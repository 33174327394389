/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const OPTION_TYPE_IMAGE = '2';
export const OPTION_TYPE_COLOR = '1';
export const OPTION_TYPE_TEXT = '0';
export const validOptionTypes = [OPTION_TYPE_TEXT, OPTION_TYPE_COLOR, OPTION_TYPE_IMAGE];

export const IN_STOCK = 'IN_STOCK';

// Attributes must be sorted by display priority (first is the most important)
export const TAG_ATTRIBUTES = [
    {
        code: 'is_finejo',
        label: __('fineJewelryProduct'),
        iconClass: 'icon-iconic'
    }, {
        code: 'isIconique',
        label: __('iconicProduct'),
        iconClass: 'icon-iconic'
    }, {
        code: 'is_new',
        label: __('newProduct'),
        iconClass: 'icon-star'
    }
];
