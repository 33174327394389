/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { DeviceType } from 'Type/Device';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Popup from 'Component/Popup';
import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import { FAVORIS_APP_POPUP } from './FavorisAppPopup.config';

import './FavorisAppPopup.style';

/** @namespace Component/FavorisAppPopup/Component */
export class FavorisAppPopup extends PureComponent {
    static propTypes = {
        showPopup: PropTypes.func.isRequired,
        code: PropTypes.string,
        device: DeviceType.isRequired
    };

    static defaultProps = {
        code: ''
    };

    state = {
        isAccepted: this.getAcceptCookieValue()
    };

    componentDidMount() {
        const { isAccepted } = this.state;
        const { showPopup, device } = this.props;

        if (!isAccepted && device.ios) {
            showPopup()
        }
    }

    getAcceptCookieValue() {
        const { code } = this.props;
        const param = `${ FAVORIS_APP_POPUP }_${ code }`;

        return !!BrowserDatabase.getItem(param);
    }

    acceptCookies = () => {
        const { code } = this.props;
        const param = `${ FAVORIS_APP_POPUP }_${ code }`;

        document.querySelector('.Header-Button_type_close').click()
        BrowserDatabase.setItem(true, param, ONE_MONTH_IN_SECONDS);
        this.setState({ isAccepted: true });
    };

    render() {
        return (
            <Popup
              id={ FAVORIS_APP_POPUP }
              mix={ { block: 'FavorisAppPopup' } }
              onCloseCallback={ this.acceptCookies }
            >
              <div block="FavorisAppPopup">
                  <ContentWrapper
                    label="Favoris popup"
                    mix={ { block: 'FavorisAppPopup', elem: 'Wrapper' } }
                    wrapperMix={ { block: 'FavorisAppPopup', elem: 'ContentWrapper' } }
                  >
                      <p block="FavorisAppPopup" elem="Content">
                          { __('Favoris_popup_text_1') }
                          <span className="icon-share" />
                          { __('Favoris_popup_text_2') }
                      </p>
                  </ContentWrapper>
              </div>
            </Popup>
        );
    }
}

export default FavorisAppPopup;
