/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent, createRef } from 'react';
import gsap from 'gsap';
import { getSortedItems } from 'Util/Menu';
import Link from 'Component/Link';
import { SECOND_MENU_ITEMS } from './MobileMenu.config';

import './MobileMenu.style';

/** @namespace Component/MobileMenu/Component */
export class MobileMenu extends PureComponent {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        defaultItems: PropTypes.array,
        currentItems: PropTypes.array,
        setCurrentItems: PropTypes.func.isRequired,
        closeMenu: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired
    };

    static defaultProps = {
        defaultItems: [],
        currentItems: []
    };

    mobileMenuBackgroundRef = createRef();

    mobileMenuPaneRef = createRef();

    componentDidUpdate(prevProps) {
        const {
            isOpen
        } = this.props;

        if (prevProps.isOpen !== isOpen) {
            if (isOpen) {
                this.open();
            } else {
                this.close();
            }
        }
    }

    open() {
        gsap.to(this.mobileMenuBackgroundRef.current, { autoAlpha: 1 });
        gsap.to(this.mobileMenuPaneRef.current, { x: 0 });
    }

    close() {
        const {
            setCurrentItems,
            defaultItems
        } = this.props;

        gsap.to(this.mobileMenuBackgroundRef.current, { autoAlpha: 0 });
        gsap.to(this.mobileMenuPaneRef.current, {
            x: '-100%',
            onComplete: () => {
                setCurrentItems(defaultItems);
            }
        });
    }

    renderItem = (item) => {
        const { closeMenu } = this.props;

        const {
            url,
            item_id,
            title
        } = item;

        return (
            <Link
              to={ url }
              block="MobileMenu"
              elem="Link"
              id={ item_id }
              onClick={ closeMenu }
            >
                { title }
            </Link>
        );
    };

    renderItemWithChildren = (item) => {
        const {
            item_id,
            title,
            children
        } = item;

        const { setCurrentItems } = this.props;

        const onClick = () => {
            setCurrentItems(getSortedItems(Object.values(children)));
        };

        return (
            <div
              block="MobileMenu"
              elem="Link"
              id={ item_id }
              onClick={ onClick }
            >
                <span>{ title }</span>
                <span className="icon-cheveron-right" />
            </div>
        );
    };

    getItemById = (id) => {
        const { defaultItems } = this.props;

        const flat = (array) => {
            // eslint-disable-next-line fp/no-let
            let result = [];

            array.forEach((a) => {
                result.push(a);
                if (Array.isArray(Object.values(a.children))) {
                    result = result.concat(flat(Object.values(a.children)));
                }
            });

            return result;
        };

        const items = flat(defaultItems);
        const item = items.find((el) => el.item_id === id || el.item_id === `${id}`);

        return item || null;
    };

    renderItems = (item) => {
        const { item_id, item_class } = item;
        const hasChildren = !!Object.keys(item.children).length;

        return (
            <li
              block="MobileMenu"
              elem="Item"
              key={ item_id }
              className={ item_class }
            >
                { hasChildren ? this.renderItemWithChildren(item) : this.renderItem(item) }
            </li>
        );
    };

    onBackButtonClick() {
        const {
            currentItems,
            defaultItems,
            setCurrentItems
        } = this.props;

        const items = currentItems.length ? currentItems : defaultItems;
        const parent = this.getItemById(items[0].parent_id);
        const grandParent = this.getItemById(parent.parent_id);

        if (grandParent) {
            setCurrentItems(getSortedItems(Object.values(grandParent.children)));
        } else {
            setCurrentItems(defaultItems);
        }
    }

    renderBackButton(item) {
        return (
            <div
              block="MobileMenu"
              elem="Link"
              mods={ { parentLink: true } }
              onClick={ () => this.onBackButtonClick() }
            >
                <span className="icon-cheveron-left" />
                <span>{ item.title }</span>
            </div>
        );
    }

    renderBrowseCategoriesButton(item) {
        const { closeMenu } = this.props;

        if (!item) {
            return null;
        }

        const label = (item.menu_mobile_label && item.menu_mobile_label.length)
            ? item.menu_mobile_label
            : `${ __('Browse') } ${ item.title }`;

        if (!label) {
            return null;
        }

        return (
            <li
              block="MobileMenu"
              elem="Item"
            >
                <Link
                  block="MobileMenu"
                  elem="Link"
                  to={ item.url }
                  mods={ { allCollectionsLink: true } }
                  onClick={ closeMenu }
                >
                    <span>{ label }</span>
                </Link>
            </li>
        );
    }

    renderSecondMenu() {
        const { closeMenu } = this.props;

        return (
            <>
                <div block="MobileMenu" elem="Divider" />
                { SECOND_MENU_ITEMS.map(({ label, icon, to }) => (
                    <li
                      block="MobileMenu"
                      elem="Item"
                    >
                        <Link
                          block="MobileMenu"
                          elem="Link"
                          to={ to }
                          onClick={ closeMenu }
                          mods={ { secondMenuItem: true } }
                        >
                        <span className={ icon } />
                        <span>{ label }</span>
                        </Link>
                    </li>
                )) }
                <div block="MobileMenu" elem="Divider" />
                <li
                  block="MobileMenu"
                  elem="Item"
                >
                    <a
                      block="MobileMenu"
                      elem="Link"
                      to="/login"
                      onClick={ this.handleSignOut.bind(this) }
                      mods={ { secondMenuItem: true } }
                    >
                        <span>{ __('Logout') }</span>
                    </a>
                </li>
            </>
        );
    }

    handleSignOut() {
        const {
            closeMenu,
            logout
        } = this.props;

        logout().then(() => {
            closeMenu();
        });

        window.location = '/login';
    }

    render() {
        const {
            isOpen,
            currentItems,
            defaultItems
        } = this.props;

        const items = currentItems.length ? currentItems : defaultItems;

        if (!items.length) {
            return null;
        }

        const parentItem = this.getItemById(items[0].parent_id);

        return (
            <div
              block="MobileMenu"
              mods={ { isOpen } }
            >
                <div
                  block="MobileMenu"
                  elem="Background"
                  ref={ this.mobileMenuBackgroundRef }
                />
                <div
                  block="MobileMenu"
                  elem="Pane"
                  ref={ this.mobileMenuPaneRef }
                >
                    { parentItem && this.renderBackButton(parentItem) }
                    <div block="MobileMenu" elem="Categories">
                        <ul
                          block="MobileMenu"
                          elem="ItemList"
                        >
                            { items.map(this.renderItems) }
                            { parentItem && this.renderBrowseCategoriesButton(parentItem) }
                            { !parentItem && this.renderSecondMenu() }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default MobileMenu;
