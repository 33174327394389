import {
    UPDATE_WARRANTY_CONFIG,
    UPDATE_WARRANTY_CONFIG_LOAD_STATUS
} from 'Store/WarrantyConfig/WarrantyConfig.action';

/** @namespace Store/WarrantyConfig/Reducer/getInitialState */
export const getInitialState = () => ({
    iframeUrl: '',
    isLoading: true
});

/** @namespace Store/WarrantyConfig/Reducer */
export const WarrantyConfigReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_WARRANTY_CONFIG:
        const { warrantyConfig: configResponse } = action.data;

        return {
            ...state,
            iframeUrl: configResponse.iframeUrl
        };
    case UPDATE_WARRANTY_CONFIG_LOAD_STATUS:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    default:
        return state;
    }
};

export default WarrantyConfigReducer;
