export const UPDATE_TAG_LIST = 'UPDATE_TAG_LIST';
export const UPDATE_TAG_LIST_LOAD_STATUS = 'UPDATE_TAG_LIST_LOAD_STATUS';

/** @namespace Store/BcsPostTagList/Action/updateTagList */
export const updateTagList = (data) => ({
    type: UPDATE_TAG_LIST,
    data
});

/**
 * Update loading status
 * @param {Boolean} status Loading indication boolean
 * @return {void}
 * @namespace Store/BcsPostTagList/Action/updateTagListLoadStatus
 */
export const updateTagListLoadStatus = (status) => ({
    type: UPDATE_TAG_LIST_LOAD_STATUS,
    isLoading: status
});
