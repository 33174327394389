/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import WishlistQuery from 'Query/Wishlist.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { isSignedIn } from 'Util/Auth';
import { fetchMutation, getErrorMessage } from 'Util/Request';

import ShareWishlistPopup from './ShareWishlistPopup.component';
import { customerType } from 'Type/Account';

/** @namespace Component/ShareWishlistPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showError: (message) => dispatch(showNotification('error', message)),
    hidePopup: () => dispatch(showPopup('', {}))
});

/** @namespace Component/ShareWishlistPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer
});

/** @namespace Component/ShareWishlistPopup/Container/shareWishlistPopupContainer */
export class ShareWishlistPopupContainer extends PureComponent {
    static propTypes = {
        customer: customerType.isRequired,
        showError: PropTypes.func.isRequired,
        hidePopup: PropTypes.func.isRequired
    };

    state = {
        isSubmitSuccess: false
    }

    containerFunctions = {
        handleFormData: this.handleFormData.bind(this),
        resetSubmitSuccess: this.resetSubmitSuccess.bind(this)
    };

    resetSubmitSuccess() {
        const { hidePopup } = this.props;

        hidePopup();
        this.setState({ isSubmitSuccess: false });
    }

    handleFormData(fields) {
        const { showError } = this.props;
        const { message, emails: initialEmails } = fields;
        const emails = initialEmails.split(',').map((email) => email.trim());

        if (!isSignedIn()) {
            return;
        }

        const {
            customer: { wishlist_copy_email }
        } = this.props

        fetchMutation(WishlistQuery.getShareWishlistMutation({ message, emails: [...emails, wishlist_copy_email] })).then(
            /** @namespace Component/ShareWishlistPopup/Container/handleFormDataFetchMutationThen */
            () => {
                // hidePopup();
                this.setState({
                    isSubmitSuccess: true
                })
            },
            /** @namespace Component/ShareWishlistPopup/Container/handleFormDataFetchMutationCatch */
            (error) => showError(getErrorMessage(error))
        );
    }

    render() {
        return (
            <ShareWishlistPopup
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareWishlistPopupContainer);
