import { UPDATE_SELLIN_CONFIG } from './SellInConfig.action';

export const initialState = {
    sellInConfig: {}
};

export const SellInConfigReducer = (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_SELLIN_CONFIG:
        return {
            ...state,
            sellInConfig: action.sellInConfig
        };

    default:
        return state;
    }
};

export default SellInConfigReducer;
