export const UPDATE_PUSH_NOTIFICATION = 'UPDATE_PUSH_NOTIFICATION';
export const UPDATE_PUSH_NOTIFICATION_LOAD_STATUS = 'UPDATE_PUSH_NOTIFICATION_LOAD_STATUS';

/** @namespace Store/PushNotification/Action/updatePushNotification */
export const updatePushNotification = (data) => ({
    type: UPDATE_PUSH_NOTIFICATION,
    data
});

/**
 * Update loading status
 * @param {Boolean} status Loading indication boolean
 * @return {void}
 * @namespace Store/PushNotification/Action/updatePushNotificationLoadStatus
 */
export const updatePushNotificationLoadStatus = (status) => ({
    type: UPDATE_PUSH_NOTIFICATION_LOAD_STATUS,
    isLoading: status
});
