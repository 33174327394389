export const UPDATE_DOCS_CAT_LIST = 'UPDATE_DOCS_CAT_LIST';
export const UPDATE_DOCS_CAT_LOAD_STATUS = 'UPDATE_DOCS_CAT_LOAD_STATUS';

/** @namespace Store/DocumentsCatagory/Action/updateSpotlightPostList */
export const updateDocumentsCatagoryList = (data) => ({
    type: UPDATE_DOCS_CAT_LIST,
    data
});

/** @namespace Store/DocumentsCatagory/Action/updateSpotlightPostListLoadStatus */
export const updateDocumentsCatagoryLoadStatus = (status) => ({
    type: UPDATE_DOCS_CAT_LOAD_STATUS,
    isLoading: status
});
