/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_PRODUCT_DETAILS = 'UPDATE_PRODUCT_DETAILS';
export const UPDATE_PRODUCT_DETAILS_LOADING_STATUS = 'UPDATE_PRODUCT_DETAILS_LOADING_STATUS';

/**
 * Update product item
 * @param  {Object} product The product fetched from the query
 * @return {void}
 * @namespace Store/Product/Action/updateProductDetails
 */
export const updateProductDetails = (product) => ({
    type: UPDATE_PRODUCT_DETAILS,
    product
});

/**
 * Update Loading Status
 * @param  {Boolean} status
 * @return {void}
 * @namespace Store/Product/Action/updateProductDetailsLoadingStatus
 */
export const updateProductDetailsLoadingStatus = (status) => ({
    type: UPDATE_PRODUCT_DETAILS_LOADING_STATUS,
    status
});
