/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './BurgerMenu.style';

/**
  * BurgerMenu
  * @class BurgerMenu
  * @namespace Component/BurgerMenu/Component
  */
export class BurgerMenu extends PureComponent {
     static propTypes = {
         isMobileMenuOpen: PropTypes.bool.isRequired,
         onBurgerMenuClick: PropTypes.func.isRequired
     };

     render() {
         const { isMobileMenuOpen, onBurgerMenuClick } = this.props;

         return (
            <div
              block="BurgerMenu"
              onClick={ onBurgerMenuClick }
              aria-label="Toggle mobile menu"
              mods={ { isMobileMenuOpen } }
            >
              <div
                block="BurgerMenu"
                elem="Inner"
              />
            </div>
         );
     }
}

export default BurgerMenu;
