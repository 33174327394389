/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS';
export const UPDATE_SELLERS_LIST = 'UPDATE_SELLERS_LIST';

export const updateSellersList = (sellers) => ({
    type: UPDATE_SELLERS_LIST,
    sellers
});

export const updateLoadingStatus = (isLoading) => ({
    type: UPDATE_LOADING_STATUS,
    isLoading
});
