/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import { DeviceType } from 'Type/Device';
import media from 'Util/Media';

import {
    PDP,
    CATEGORY,
    CUSTOMER_ACCOUNT,
    CUSTOMER_SUB_ACCOUNT,
    CUSTOMER_ACCOUNT_PAGE,
    CUSTOMER_WISHLIST,
    MY_ACCOUNT
} from 'Component/Header/Header.config';

/** @namespace Component/MenuItem/Component */
export class MenuItem extends PureComponent {
    static propTypes = {
        activeMenuItemsStack: PropTypes.array.isRequired,
        item: PropTypes.object.isRequired,
        itemMods: PropTypes.object,
        handleCategoryHover: PropTypes.func.isRequired,
        isLink: PropTypes.bool,
        onItemClick: PropTypes.func,
        device: DeviceType.isRequired,
        breadcrumbsState: PropTypes.array.isRequired,
        navigationState: PropTypes.object.isRequired
    };

    static defaultProps = {
        itemMods: {},
        isLink: false,
        onItemClick: () => {}
    };

    renderItemContentImage(icon, itemMods) {
        const { device } = this.props;
        const { isBanner, isLogo, type } = itemMods;

        if (!icon
            || (!device.isMobile && !isBanner && !isLogo)
            || (type === 'subcategory')
        ) {
            return null;
        }

        return (
            <Image
              mix={ { block: 'Menu', elem: 'Image', mods: itemMods } }
              src={ icon && media(icon) }
              ratio="custom"
            />
        );
    }

    renderItemContentTitle(isBanner, title) {
        if (isBanner) {
            return (
                <button
                  block="Menu"
                  elem="Button"
                  mix={ { block: 'Button' } }
                >
                    { title }
                </button>
            );
        }

        return title;
    }

    isPathCommon(menu_url) {
        const { breadcrumbsState, navigationState } = this.props;

        const authorizedRoutes = [
            PDP,
            CATEGORY,
            CUSTOMER_ACCOUNT,
            CUSTOMER_SUB_ACCOUNT,
            CUSTOMER_ACCOUNT_PAGE,
            CUSTOMER_WISHLIST,
            MY_ACCOUNT
        ];

        if ((breadcrumbsState && breadcrumbsState.length <= 0) || !authorizedRoutes.includes(navigationState.name)) {
            return false;
        }

        const bc_url = breadcrumbsState[breadcrumbsState.length - 1].url;

        const a_url = menu_url.pathname ? menu_url.pathname.split('/') : menu_url.split('/');
        const b_url = bc_url.pathname ? bc_url.pathname.split('/') : bc_url.split('/');

        if (a_url[0] !== '') {
            a_url.unshift(b_url[0]);
        }

        for (let i = 0; i < a_url.length; i++) {
            if (a_url[i] !== b_url[i]) {
                return false;
            }
        }

        return true;
    }

    renderItemContent(item, itemMods = {}) {
        const { title, icon, url } = item;
        const { isBanner } = itemMods;
        const isActive = this.isPathCommon(url, window.location.pathname) && itemMods?.type === 'main';

        return (
            <figure
              block="Menu"
              elem="ItemFigure"
              mods={ { ...itemMods, isActive } }
            >
                { this.renderItemContentImage(icon, itemMods) }
                <figcaption
                  block="Menu"
                  elem="ItemCaption"
                  mods={ itemMods }
                >
                    { this.renderItemContentTitle(isBanner, title) }
                </figcaption>
            </figure>
        );
    }

    renderItemLinkContent() {
        const {
            activeMenuItemsStack,
            item,
            itemMods,
            handleCategoryHover,
            onItemClick
        } = this.props;

        const {
            url,
            item_id,
            item_class
        } = item;

        const isHovered = activeMenuItemsStack.includes(item_id);

        return (
            <Link
              to={ url }
              block="Menu"
              elem="Link"
              id={ item_id }
              onMouseEnter={ handleCategoryHover }
              mods={ { isHovered } }
              onClick={ onItemClick }
              className={ item_class }
            >
                { this.renderItemContent(item, itemMods) }
            </Link>
        );
    }

    render() {
        const { item, itemMods, isLink } = this.props;

        if (isLink) {
            return this.renderItemLinkContent();
        }

        return this.renderItemContent(item, itemMods);
    }
}

export default MenuItem;
