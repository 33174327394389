import { createRef, PureComponent } from 'react';

import { isSignedIn } from 'Util/Auth';

import Html from 'Component/Html';
import ContentWrapper from 'Component/ContentWrapper';

import PropTypes from 'prop-types';
import { PushNotificationType } from 'Type/PushNotification';

import './PushNotification.style';

/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
/** @namespace Component/PushNotification/Container */
export class PushNotification extends PureComponent {
    static propTypes = {
        notification: PushNotificationType,
        canDisplay: PropTypes.bool,
        handleClose: PropTypes.func.isRequired
    };

    notification = createRef();

    static defaultProps = {
        notification: null,
        canDisplay: false
    };

    componentDidUpdate(prevProps) {
        const {
            canDisplay: prevCanDisplay
        } = prevProps;

        const {
            canDisplay
        } = this.props;

        if (!prevCanDisplay && canDisplay) {
            if (isSignedIn()) {
                this.enterEl();
            }
        }

        if (!isSignedIn()) {
            this.leaveEl();
        }
    }

    handleClick() {
        const {
            handleClose
        } = this.props;

        this.leaveEl();
        handleClose();
    }

    enterEl() {
        if (this.notification && this.notification.current) {
            this.notification.current.classList.remove('hidden')
        }
    }

    leaveEl() {
        if (this.notification && this.notification.current) {
            this.notification.current.classList.add('hidden')
        }
    }

    render() {
        const {
            notification
        } = this.props;

        return (
            <div
              block="PushNotification"
              className="hidden"
              ref={ this.notification }
            >
                <ContentWrapper
                  label="PushNotification popup"
                  mix={ { block: 'PushNotification', elem: 'Container' } }
                  wrapperMix={ { block: 'PushNotification', elem: 'Wrapper' } }
                >
                    <div block="PushNotification" elem="Title">
                        { (notification) ? notification.title : '' }
                    </div>
                    <div block="PushNotification" elem="Content">
                        <Html content={ (notification) ? notification.description : '' } />
                    </div>
                    <button
                      block="PushNotification"
                      elem="CloseBtn"
                      aria-label={ __('Close') }
                      onClick={ this.handleClick.bind(this) }
                    />
                </ContentWrapper>
            </div>
        );
    }
}

export default PushNotification;
