import {
    UPDATE_FAV_POST_LIST_LOAD_STATUS,
    ADD_FAV,
    REMOVE_FAV,
    CLEAR_FAV_LIST
} from 'Store/BcsPostFav/BcsPostFav.action';

/** @namespace Store/BcsPostFav/Reducer/getInitialState */
export const getInitialState = () => ({
    favs: [],
    isLoading: true
});

export const removeFav = (list, id) => list.filter((fav) => fav !== id);

export const addFav = (list, id) => [...list, id].filter((fav, index, array) => array.indexOf(fav) === index);

/** @namespace Store/BcsPostFav/Reducer */
export const BcsPostFavReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_FAV_POST_LIST_LOAD_STATUS:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };
    case ADD_FAV:
        return {
            ...state,
            favs: addFav(state.favs, action.id)
        };
    case REMOVE_FAV:
        return {
            ...state,
            favs: removeFav(state.favs, action.id)
        };
    case CLEAR_FAV_LIST:
        return {
            ...getInitialState()
        };
    default:
        return state;
    }
};

export default BcsPostFavReducer;
