/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    UPDATE_LOADING_STATUS,
    UPDATE_SELLERS_LIST
} from './Sellers.action';

/** @namespace Store/Sellers/Reducer/getInitialState */
export const getInitialState = () => ({
    sellers: [],
    isLoading: false
});

/** @namespace Store/Sellers/Reducer */
export const SellersReducer = (
    state = getInitialState(),
    { type, sellers, isLoading }
) => {
    switch (type) {
    case UPDATE_LOADING_STATUS:
        return {
            ...state,
            isLoading
        };
    case UPDATE_SELLERS_LIST:
        return {
            ...state,
            sellers
        };
    default:
        return state;
    }
};

export default SellersReducer;
