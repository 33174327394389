/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { connect } from 'react-redux';

import CmsBlockQuery from 'Query/CmsBlock.query';
import DataContainer from 'Util/Request/DataContainer';

import Footer from './Footer.component';

/** @namespace Component/Footer/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

export class FooterContainer extends DataContainer {
    state = {
        isLoading: true,
        footerContent: []
    }

    componentDidMount() {
        this.getFooterContent();
    }

    getFooterContent() {
        this.fetchData(
            [CmsBlockQuery.getQuery({ identifiers: ['Footer-links'] })],
            ({ cmsBlocks: { items } }) => {
                if (!items.length) {
                    this.setState({
                        isLoading: false,
                        footerContent: []
                    });

                    return;
                }

                this.setState({
                    isLoading: false,
                    footerContent: this.parseCmsData(items[0].content)
                });
            }
        );
    }

    parseCmsData(cmsData) {
        const el = document.createElement('div')
        el.innerHTML = cmsData;

        const parsedData = []

        el.querySelectorAll('a').forEach((link) => {
            parsedData.push({
                title: link.innerText,
                href: link.getAttribute('href'),
                icon: link.getAttribute('data-icon') || null
            })
        });

        return parsedData;
    }

    render() {
        return (
            <Footer
              { ...this.props }
              { ...this.state }
            />
        );
    }
}

/** @namespace Component/Footer/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);
