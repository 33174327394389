/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import AddToCart from 'Component/AddToCart';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import ProductAttributeValue from 'Component/ProductAttributeValue';
import ProductCompareButton from 'Component/ProductCompareButton';
import ProductPrice from 'Component/ProductPrice';
import ProductReviewRating from 'Component/ProductReviewRating';
import ProductWishlistButton from 'Component/ProductWishlistButton';
import TextPlaceholder from 'Component/TextPlaceholder';
import TierPrices from 'Component/TierPrices';
import { DeviceType } from 'Type/Device';
import { ProductType } from 'Type/ProductList';
import { BUNDLE, CONFIGURABLE } from 'Util/Product';
import {
    customerType,
    PRICE_DISPLAY_NO_PRICE,
    PRICE_DISPLAY_ON_DEMAND
} from 'Type/Account';

import {
    OPTION_TYPE_COLOR,
    OPTION_TYPE_IMAGE,
    validOptionTypes,
    TAG_ATTRIBUTES
} from './ProductCard.config';

import './ProductCard.style';

/* eslint-disable react/boolean-prop-naming */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/**
 * Product card
 * @class ProductCard
 * @namespace Component/ProductCard/Component
 */
export class ProductCard extends PureComponent {
    static propTypes = {
        linkTo: PropTypes.shape({}),
        product: ProductType.isRequired,
        customer: customerType.isRequired,
        isProductNew: PropTypes.bool.isRequired,
        device: DeviceType.isRequired,
        productOrVariant: ProductType.isRequired,
        thumbnail: PropTypes.string,
        availableVisualOptions: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
            type: PropTypes.string
        })).isRequired,
        getAttribute: PropTypes.func.isRequired,
        registerSharedElement: PropTypes.func.isRequired,
        children: PropTypes.element,
        isLoading: PropTypes.bool,
        mix: PropTypes.shape({}),
        renderContent: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
        isConfigurableProductOutOfStock: PropTypes.func.isRequired,
        isBundleProductOutOfStock: PropTypes.func.isRequired,
        hideWishlistButton: PropTypes.bool,
        hideCompareButton: PropTypes.bool,
        siblingsHaveBrands: PropTypes.bool,
        setSiblingsHaveBrands: PropTypes.func,
        siblingsHavePriceBadge: PropTypes.bool,
        setSiblingsHavePriceBadge: PropTypes.func,
        siblingsHaveTierPrice: PropTypes.bool,
        setSiblingsHaveTierPrice: PropTypes.func,
        siblingsHaveConfigurableOptions: PropTypes.bool,
        setSiblingsHaveConfigurableOptions: PropTypes.func,
        layout: PropTypes.string,
        wishlist: PropTypes.bool,
        removeItem: PropTypes.func,
        hasDiamondPricing: PropTypes.bool,
        diamondPricing: PropTypes.object
    };

    static defaultProps = {
        thumbnail: '',
        linkTo: {},
        children: null,
        isLoading: false,
        mix: {},
        renderContent: false,
        hideWishlistButton: false,
        hideCompareButton: false,
        siblingsHaveBrands: false,
        setSiblingsHaveBrands: () => null,
        siblingsHavePriceBadge: false,
        setSiblingsHavePriceBadge: () => null,
        siblingsHaveTierPrice: false,
        setSiblingsHaveTierPrice: () => null,
        siblingsHaveConfigurableOptions: false,
        setSiblingsHaveConfigurableOptions: () => null,
        layout: 'grid',
        wishlist: false,
        removeItem: () => null,
        hasDiamondPricing: false,
        diamondPricing: {}
    };

    contentObject = {
        renderCardLinkWrapper: this.renderCardLinkWrapper.bind(this),
        pictureBlock: {
            picture: this.renderPicture.bind(this)
        },
        content: {
            review: this.renderReviews.bind(this),
            productPrice: this.renderProductPrice.bind(this),
            confOptions: this.renderVisualConfigurableOptions.bind(this),
            tierPrice: this.renderTierPrice.bind(this),
            mainDetails: this.renderMainDetails.bind(this),
            additionalProductDetails: this.renderAdditionalProductDetails.bind(this)
        }
    };

    imageRef = createRef();

    registerSharedElement = () => {
        const { registerSharedElement } = this.props;
        registerSharedElement(this.imageRef);
    };

    renderConfigurablePriceBadge() {
        const {
            product: { type_id },
            siblingsHavePriceBadge,
            setSiblingsHavePriceBadge
        } = this.props;

        if (type_id !== CONFIGURABLE) {
            return null;
        }

        if (!siblingsHavePriceBadge) {
            setSiblingsHavePriceBadge();
        }

        return (
            <p
              mix={ {
                  block: 'ProductCard',
                  elem: 'PriceBadge'
              } }
            >
                { __('As Low as') }
            </p>
        );
    }

    renderEmptyProductPrice() {
        return (
            <div
              block="ProductCard"
              elem="PriceWrapper"
              mods={ { isEmpty: true } }
            />
        );
    }

    renderProductPrice() {
        const {
            product: { price_range, type_id },
            isConfigurableProductOutOfStock,
            isBundleProductOutOfStock,
            hasDiamondPricing,
            diamondPricing,
            customer
        } = this.props;

        if (!price_range) {
            return <TextPlaceholder />;
        }

        switch (type_id) {
        case CONFIGURABLE:
            if (isConfigurableProductOutOfStock()) {
                return this.renderEmptyProductPrice();
            }
            break;
        case BUNDLE:
            if (isBundleProductOutOfStock()) {
                return this.renderEmptyProductPrice();
            }
            break;
        default:
            break;
        }

        if (customer.price_display === PRICE_DISPLAY_NO_PRICE || customer.price_display === PRICE_DISPLAY_ON_DEMAND) {
            return null;
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
                { this.renderTierPrice() }
                { this.renderConfigurablePriceBadge() }
                { (!hasDiamondPricing || !diamondPricing.type) ? (
                    <ProductPrice
                      price={ price_range }
                      mix={ { block: 'ProductCard', elem: 'Price' } }
                    />
                ) : (
                    <div
                      block="ProductCard"
                      elem="Diamonds"
                      mods={ { [`${diamondPricing.type}`]: diamondPricing.type.length > 0 } }
                    >
                        { Array.from(Array(diamondPricing.value), (item, index) => (
                            <span className="icon-diamant" key={ index } />
                        )) }
                    </div>
                ) }
            </div>
        );
    }

    renderTierPrice() {
        const {
            productOrVariant,
            siblingsHaveTierPrice,
            setSiblingsHaveTierPrice
        } = this.props;
        const { price_tiers } = productOrVariant;

        if (!price_tiers || !price_tiers.length) {
            return null;
        }

        if (!siblingsHaveTierPrice) {
            setSiblingsHaveTierPrice();
        }

        return (
            <TierPrices
              product={ productOrVariant }
              isLowestPrice
            />
        );
    }

    renderImageVisualOption(label, value, i) {
        return (
          <img
            key={ i }
            block="ProductCard"
            elem="Image"
            src={ `/media/attribute/swatch/swatch_thumb/110x90${value}` }
            alt={ label }
          />
        );
    }

    renderVisualOption = ({ label, value, type }, i) => {
        if (type === OPTION_TYPE_IMAGE) {
            return this.renderImageVisualOption(label, value, i);
        }

        const isColor = type === OPTION_TYPE_COLOR;

        return (
            <span
              block="ProductCard"
              elem={ isColor ? 'Color' : 'String' }
              key={ i }
              style={ isColor ? { backgroundColor: value } : {} }
              aria-label={ isColor ? label : '' }
              title={ isColor ? '' : label }
            >
                { isColor ? '' : value }
            </span>
        );
    };

    renderVisualConfigurableOptions(hidden = true) {
        const {
            siblingsHaveConfigurableOptions,
            setSiblingsHaveConfigurableOptions,
            availableVisualOptions,
            device
        } = this.props;

        if (hidden) {
            return null;
        }

        if (device.isMobile || !availableVisualOptions.length) {
            return <div block="ProductCard" elem="ConfigurableOptions" />;
        }

        if (!validOptionTypes.includes(availableVisualOptions[0].type)) {
            return <div block="ProductCard" elem="ConfigurableOptions" />;
        }

        if (!siblingsHaveConfigurableOptions) {
            setSiblingsHaveConfigurableOptions();
        }

        return (
            <div block="ProductCard" elem="ConfigurableOptions">
                { availableVisualOptions.map(this.renderVisualOption) }
            </div>
        );
    }

    renderPicture(mix = {}) {
        const { product: { id, name }, thumbnail } = this.props;

        this.sharedComponent = (
            <Image
              imageRef={ this.imageRef }
              src={ thumbnail }
              alt={ name }
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture', mix } }
              isPlaceholder={ !id }
              width="auto"
              height="auto"
            />
        );

        return (
            <>
                { this.sharedComponent }
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ thumbnail }
                />
            </>
        );
    }

    renderReviews() {
        const {
            product: {
                review_summary: {
                    rating_summary
                } = {}
            },
            layout
        } = this.props;

        if (!rating_summary) {
            return null;
        }

        return (
            <div
              block="ProductCard"
              elem="Reviews"
              mods={ { layout } }
            >
                <ProductReviewRating summary={ rating_summary || 0 } />
            </div>
        );
    }

    renderProductCompareButton() {
        const {
            product: { id },
            hideCompareButton
        } = this.props;

        if (hideCompareButton) {
            return null;
        }

        return (
            <ProductCompareButton
              productId={ id }
              mix={ {
                  block: 'ProductCompareButton',
                  mods: { isGrey: true }
              } }
            />
        );
    }

    renderProductCardWishlistButton() {
        const { product, hideWishlistButton } = this.props;

        if (hideWishlistButton) {
            return null;
        }

        return (
            <ProductWishlistButton
              product={ product }
              mix={ { block: 'ProductCard', elem: 'WishListButton' } }
            />
        );
    }

    renderProductActions() {
        return (
            <div block="ProductCard" elem="ProductActions">
                { this.renderProductCardWishlistButton() }
                { /* { this.renderProductCompareButton() } */ }
            </div>
        );
    }

    renderBrandValue() {
        const {
            getAttribute,
            siblingsHaveBrands,
            setSiblingsHaveBrands
        } = this.props;
        const {
            product_list_content: {
                attribute_to_display
            } = {}
        } = window.contentConfiguration;
        const brand = getAttribute(attribute_to_display || 'brand');

        if (!brand) {
            return null;
        }

        if (!siblingsHaveBrands) {
            setSiblingsHaveBrands();
        }

        return (
            <ProductAttributeValue
              attribute={ brand }
              isFormattedAsText
              mix={ {
                  block: 'ProductCard',
                  elem: 'BrandAttributeValue'
              } }
            />
        );
    }

    renderType() {
        const {
            product,
            isProductNew
        } = this.props;

        const type = product.type_label && product.type_label.length > 0 ? product.type_label : '';
        const tag = TAG_ATTRIBUTES.find(({ code }) => !!product[code] || (code === 'is_new' && isProductNew));

        return (
            <div block="ProductCard" elem="Types">
                { tag && (
                    <>
                        <span className={ tag.iconClass } />
                        <span block="ProductCard" elem="Type">{ tag.label }</span>
                    </>
                ) }
                { type && tag && (<span className="icon-circle" />) }
                { type && <span block="ProductCard" elem="Type"><TextPlaceholder content={ type } /></span> }
            </div>
        );
    }

    renderAdditionalProductDetails() {
        return (
            <div block="ProductCard" elem="Brand">
                { /* { this.renderBrandValue() } */ }
                { this.renderType() }
            </div>
        );
    }

    renderMainDetails() {
        const { product: { name } } = this.props;

        return (
            <div block="ProductCard" elem="Link">
                <p
                  block="ProductCard"
                  elem="Name"
                  mods={ { isLoaded: !!name } }
                >
                    <TextPlaceholder content={ name } length="medium" />
                </p>
            </div>
        );
    }

    renderCardLinkWrapper(children, mix = {}) {
        const { linkTo, product: { url } } = this.props;

        if (!url) {
            return (
                <div
                  block="ProductCard"
                  elem="Link"
                >
                    { children }
                </div>
            );
        }

        return (
            <Link
              block="ProductCard"
              elem="Link"
              to={ linkTo }
              onClick={ this.registerSharedElement }
              mix={ mix }
            >
              { children }
            </Link>
        );
    }

    renderAddToCart() {
        const {
            product,
            product: {
                type_id
            }
        } = this.props;
        const configurableVariantIndex = -1;
        const quantity = 1;
        const groupedProductQuantity = {};
        const productOptionsData = {};

        if (type_id !== 'simple') {
            return this.renderCardLinkWrapper(
                <button block="Button">{ __('Add To Cart') }</button>
            );
        }

        return (
            <AddToCart
              product={ product }
              configurableVariantIndex={ configurableVariantIndex }
              mix={ { block: 'ProductActions', elem: 'AddToCart' } }
              quantity={ quantity }
              groupedProductQuantity={ groupedProductQuantity }
              productOptionsData={ productOptionsData }
            />
        );
    }

    renderProductRefContent() {
        const { product: { sku } } = this.props;

        if (!sku) {
            return null;
        }

        return (
            <div block="ProductCard" elem="RefContainer">
                <span block="ProductCard" elem="RefLabel">{ __('REF : ') }</span>
                <span block="ProductCard" elem="RefValue">{ sku }</span>
            </div>
        );
    }

    renderCardContent() {
        const { renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                        { this.renderReviews() }
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderAdditionalProductDetails() }
                        <div block="ProductCard" elem="Wrapper">
                          { this.renderMainDetails() }
                          { this.renderProductPrice() }
                        </div>
                        { this.renderVisualConfigurableOptions(true) }
                    </div>
                </>
            ))
        );
    }

    renderWishlistCardContent() {
        const { product: { url, sku, name }, thumbnail, removeItem } = this.props;
        const productUrl = window.location.origin + url;

        return (
            <>
                <div className="productWishwlistWrapper">
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            <img
                              src={ thumbnail }
                              alt={ name }
                            />
                        </figure>
                        { /* { this.renderReviews() } */ }
                    </div>
                    <div block="ProductCard" elem="Content">
                        <div block="ProductCard" elem="sku">
                            <span block="ProductCard" elem="skuItem">
                                { __('REF : ') }
                            </span>
                            <span block="ProductCard" elem="skuItem">
                                { sku }
                            </span>
                        </div>
                        { this.renderAdditionalProductDetails() }
                        <div block="ProductCard" elem="Wrapper">
                          { this.renderMainDetails() }
                          { this.renderProductPrice() }
                        </div>
                        { this.renderVisualConfigurableOptions(true) }
                    </div>
                </div>
                <div
                  className="productWishwlistRemove"
                  onClick={ removeItem }
                  onKeyDown={ () => { } }
                  role="button"
                  aria-label="Wishlist"
                  tabIndex={ 0 }
                >
                    <span className="icon-close" />
                    <span>{ __('Delete') }</span>
                </div>
                <a className="productWishwlistLink" href={ productUrl }>{ __('Show product') }</a>
            </>
        );
    }

    renderCardListContent() {
        const { children, layout, renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <div block="ProductCard" elem="Link">
                { this.renderCardLinkWrapper(
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                ) }
                <div block="ProductCard" elem="Content" mods={ { layout } }>
                    <div block="ProductCard" elem="MainInfo">
                        { this.renderAdditionalProductDetails() }
                        { this.renderMainDetails() }
                        { this.renderReviews() }
                    </div>
                    <div block="ProductCard" elem="AttributeWrapper">
                        { this.renderProductPrice() }
                        { this.renderVisualConfigurableOptions(true) }
                    </div>
                    <div block="ProductCard" elem="ActionWrapper">
                        { this.renderAddToCart() }
                        { this.renderProductActions() }
                    </div>
                    <div block="ProductCard" elem="AdditionalContent">
                        { children }
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            children,
            mix,
            isLoading,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions,
            layout,
            wishlist
        } = this.props;

        const mods = {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions
        };

        if (layout === 'list') {
            return (
                <li
                  block="ProductCard"
                  mods={ mods }
                  mix={ mix }
                >
                    <Loader isLoading={ isLoading } />
                    { this.renderCardListContent() }
                </li>
            );
        }

        if (wishlist) {
            return (
                <li
                  block="ProductCard"
                  mods={ mods }
                  mix={ mix }
                >
                    <Loader isLoading={ isLoading } />
                    { /* { this.renderProductRefContent() } */ }
                    { this.renderWishlistCardContent() }
                    { /* { this.renderProductActions() } */ }
                    <div block="ProductCard" elem="AdditionalContent">
                        { children }
                    </div>
                </li>
            );
        }

        return (
            <li
              block="ProductCard"
              mods={ mods }
              mix={ mix }
              className={ wishlist ? 'WishlistCard' : '' }
            >
                <Loader isLoading={ isLoading } />
                { this.renderProductRefContent() }
                { this.renderCardContent() }
                { this.renderProductActions() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </li>
        );
    }
}

export default ProductCard;
