export const UPDATE_SPOTLIGHT_POST = 'UPDATE_SPOTLIGHT_POST';
export const UPDATE_SPOTLIGHT_POST_LOAD_STATUS = 'UPDATE_SPOTLIGHT_POST_LOAD_STATUS';

/** @namespace Store/BcsSpotlightPostList/Action/updateSpotlightPostList */
export const updateSpotlightPostList = (data) => ({
    type: UPDATE_SPOTLIGHT_POST,
    data
});

/**
 * Update loading status
 * @param {Boolean} status Loading indication boolean
 * @return {void}
 * @namespace Store/BcsSpotlightPostList/Action/updateSpotlightPostListLoadStatus
 */
export const updateSpotlightPostListLoadStatus = (status) => ({
    type: UPDATE_SPOTLIGHT_POST_LOAD_STATUS,
    isLoading: status
});
