/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import '/home/bymessika-preprod/pwa/releases/53/node_modules/@scandipwa/scandipwa-extensibility/runtime-helpers';
import 'Util/Polyfill';
import 'Style/main';

import PropTypes from 'prop-types';
import { render } from 'react-dom';

import App from 'Component/App';

// TODO: move this out to i18-runtime
PropTypes.string = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
]);

// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';
        navigator.serviceWorker.register(swUrl, { scope: '/' }).then((reg) => {
            reg.update();
        });
    });
}

const vp = document.querySelector('meta[name=viewport]'); // eslint-disable-line @scandipwa/scandipwa-guidelines/export-level-one

if (vp) {
    vp.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no, viewport-fit=cover'); // eslint-disable-line max-len
}

render(<App />, document.getElementById('root'));
