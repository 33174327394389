import {
    RESET_VOLLETO_TOKEN,
    UPDATE_VOLLETO_TOKEN,
    UPDATE_VOLLETO_LOAD_STATUS
} from 'Store/Volleto/Volleto.action';

/** @namespace Store/Volleto/Reducer/getInitialState */
export const getInitialState = () => ({
    token: '',
    isLoading: true
});

/** @namespace Store/Volleto/Reducer */
export const VolletoReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_VOLLETO_TOKEN:
        const { token } = action.data;

        return {
            ...state,
            token
        };
    case UPDATE_VOLLETO_LOAD_STATUS:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    case RESET_VOLLETO_TOKEN:
        return {
            ...state,
            token: ''
        };

    default:
        return state;
    }
};

export default VolletoReducer;
