/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showPopup } from 'Store/Popup/Popup.action';

import CookiePopup from './CookiePopup.component';
import { COOKIE_POPUP } from './CookiePopup.config';

import CmsBlockQuery from 'Query/CmsBlock.query';
import DataContainer from 'Util/Request/DataContainer';

/** @namespace Component/CookiePopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cookieText: state.ConfigReducer.cookie_text,
    cookieLink: state.ConfigReducer.cookie_link,
    code: state.ConfigReducer.code,
    device: state.ConfigReducer.device
});

/** @namespace Component/CookiePopup/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({
    hidePopup: () => dispatch(showPopup('', {})),
    showPopup: (payload) => dispatch(showPopup(COOKIE_POPUP, payload))
});

/** @namespace Component/CookiePopup/Container */
export class CookiePopupContainer extends DataContainer {
    static propTypes = {
        code: PropTypes.string,
        hidePopup: PropTypes.func.isRequired
    };

    static defaultProps = {
        code: ''
    };

    componentDidMount() {
        this.getCookiesContent()
    }

    getCookiesContent() {
        this.fetchData(
            [CmsBlockQuery.getQuery({ identifiers: ['cookies-popin'] })],
            ({ cmsBlocks: { items } }) => {
                if (!items.length) {
                    this.setState({
                        isLoading: false,
                        cookiesText: []
                    });

                    return;
                }

                this.setState({
                    isLoading: false,
                    cookiesText: items[0].content
                });
            }
        );
    }

    render() {
        const { code } = this.props;

        return (
            <CookiePopup
              { ...this.props }
              { ...this.state }
              key={ code }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CookiePopupContainer);
