/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const MY_ACCOUNT_URL = '/my-account';
export const MY_ACCOUNT_ELEARNING_URL = `${MY_ACCOUNT_URL}/e-learning`;
export const MY_ACCOUNT_WARRANTY_URL = `${MY_ACCOUNT_URL}/warranty`;
export const ACCOUNT_LOGIN_URL = '/account/login';
export const ACCOUNT_URL = '/account';
