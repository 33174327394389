export const UPDATE_POST = 'UPDATE_POST';
export const RESET_POST = 'RESET_POST';
export const UPDATE_POST_LOAD_STATUS = 'UPDATE_POST_LOAD_STATUS';

/** @namespace Store/BcsPost/Action/updatePost */
export const updatePost = (data) => ({
    type: UPDATE_POST,
    data
});

/** @namespace Store/BcsPost/Action/resetPost */
export const resetPost = () => ({
    type: RESET_POST
});

/**
 * Update loading status
 * @param {Boolean} status Loading indication boolean
 * @return {void}
 * @namespace Store/BcsPost/Action/updatePostLoadStatus
 */
export const updatePostLoadStatus = (status) => ({
    type: UPDATE_POST_LOAD_STATUS,
    isLoading: status
});
