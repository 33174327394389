/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { DeviceType } from 'Type/Device';

import ContentWrapper from 'Component/ContentWrapper';
import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import Popup from 'Component/Popup';
import { isSignedIn } from 'Util/Auth';
import FavorisAppPopup from 'Component/FavorisAppPopup';

import { COOKIE_POPUP } from './CookiePopup.config';

import './CookiePopup.style';

/** @namespace Component/CookiePopup/Component */
export class CookiePopup extends PureComponent {
    static propTypes = {
        showPopup: PropTypes.func.isRequired,
        hidePopup: PropTypes.func.isRequired,
        cookiesText: PropTypes.string,
        device: DeviceType.isRequired
    };

    static defaultProps = {
        cookiesText: ''
    };

    state = {
        isAccepted: this.getAcceptCookieValue(),
        isOpen: false
    };

    componentDidUpdate() {
        const { isAccepted, isOpen } = this.state;
        const { showPopup, cookiesText } = this.props;

        if (!isAccepted && !isOpen && cookiesText && isSignedIn()) {
            this.setState({ isOpen: true }); // eslint-disable-line react/no-did-update-set-state
            showPopup();
        }
    }

    getAcceptCookieValue() {
        const param = COOKIE_POPUP;

        return !!BrowserDatabase.getItem(param);
    }

    acceptCookies = () => {
        const { hidePopup, device } = this.props;
        const param = COOKIE_POPUP;

        BrowserDatabase.setItem(true, param, ONE_MONTH_IN_SECONDS);

        if (device.ios) {
            hidePopup();
        } else {
            document.querySelector('.Header-Button_type_close').click();
        }

        this.setState({ isAccepted: true });
    };

    parseHtml() {
        const { cookiesText } = this.props;

        return {
            __html: cookiesText
        };
    }

    renderCookieText() {
        return <div block="CookiePopup" elem="Content" dangerouslySetInnerHTML={ this.parseHtml() } />;
    }

    renderCTA() {
        return (
            <div block="CookiePopup" elem="CTA">
                <button block="Button" onClick={ this.acceptCookies }>
                    { __('Continue') }
                </button>
            </div>
        );
    }

    render() {
        const { isAccepted } = this.state;

        if (!isAccepted) {
            return (
                <Popup
                  id={ COOKIE_POPUP }
                  clickOutside
                  mix={ { block: 'CookiePopup' } }
                  onCloseCallback={ this.acceptCookies }
                >
                  <div block="CookiePopup">
                      <ContentWrapper
                        label="Cookie popup"
                        mix={ { block: 'CookiePopup', elem: 'Wrapper' } }
                        wrapperMix={ { block: 'CookiePopup', elem: 'ContentWrapper' } }
                      >
                          <p className="CookiePopup-title">{ __('Cookie_popup_title') }</p>
                          { this.renderCookieText() }
                          { this.renderCTA() }
                      </ContentWrapper>
                  </div>
                </Popup>
            );
        }

        return <FavorisAppPopup />;
    }
}

export default CookiePopup;
