import {
    UPDATE_MAIN_POST,
    UPDATE_MAIN_POST_LOAD_STATUS
} from 'Store/BcsMainPost/BcsMainPost.action';

/** @namespace Store/BcsMainPost/Reducer/getInitialState */
export const getInitialState = () => ({
    post: {},
    isLoading: true
});

/** @namespace Store/BcsMainPost/Reducer */
export const BcsMainPostReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_MAIN_POST:
        const { bcsMainPost } = action.data;

        return {
            ...state,
            post: bcsMainPost
        };
    case UPDATE_MAIN_POST_LOAD_STATUS:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    default:
        return state;
    }
};

export default BcsMainPostReducer;
