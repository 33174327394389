export const UPDATE_MAIN_POST = 'UPDATE_MAIN_POST';
export const UPDATE_MAIN_POST_LOAD_STATUS = 'UPDATE_MAIN_POST_LOAD_STATUS';

/** @namespace Store/BcsMainPost/Action/updateMainPost */
export const updateMainPost = (data) => ({
    type: UPDATE_MAIN_POST,
    data
});

/**
 * Update loading status
 * @param {Boolean} status Loading indication boolean
 * @return {void}
 * @namespace Store/BcsMainPost/Action/updateMainPostLoadStatus
 */
export const updateMainPostLoadStatus = (status) => ({
    type: UPDATE_MAIN_POST_LOAD_STATUS,
    isLoading: status
});
