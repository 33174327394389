export const UPDATE_WARRANTY_CONFIG = 'UPDATE_WARRANTY_CONFIG';
export const UPDATE_WARRANTY_CONFIG_LOAD_STATUS = 'UPDATE_WARRANTY_CONFIG_LOAD_STATUS';

/** @namespace Store/BcsPostWarrantyConfig/Action/updateWarrantyConfig */
export const updateWarrantyConfig = (data) => ({
    type: UPDATE_WARRANTY_CONFIG,
    data
});

/**
 * Update loading status
 * @param {Boolean} status Loading indication boolean
 * @return {void}
 * @namespace Store/BcsPostWarrantyConfig/Action/updateWarrantyConfigLoadStatus
 */
export const updateWarrantyConfigLoadStatus = (status) => ({
    type: UPDATE_WARRANTY_CONFIG_LOAD_STATUS,
    isLoading: status
});
