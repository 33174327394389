/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';
import MenuHelper, { getSortedItems } from 'Util/Menu';
import { setDefaultItems, setCurrentItems, closeMenu } from 'Store/MobileMenu/MobileMenu.action';
import MenuQuery from 'Query/Menu.query';
import DataContainer from 'Util/Request/DataContainer';
import MobileMenu from './MobileMenu.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const mapStateToProps = (state) => ({
    isOpen: state.MobileMenuReducer.isOpen,
    defaultItems: state.MobileMenuReducer.defaultItems,
    currentItems: state.MobileMenuReducer.currentItems
});

export const mapDispatchToProps = (dispatch) => ({
    setDefaultItems: (items) => dispatch(setDefaultItems(items)),
    setCurrentItems: (items) => dispatch(setCurrentItems(items)),
    closeMenu: () => dispatch(closeMenu()),
    logout: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.logout(false, dispatch)
    )
});

export class MobileMenuContainer extends DataContainer {
    __construct(props) {
        super.__construct(props);

        this.state = {
            menu: {}
        };
    }

    _getMenuOptions() {
        const { header_content: { header_menu } = {} } = window.contentConfiguration;

        return {
            identifier: header_menu || 'new-main-menu'
        };
    }

    _getMenu() {
        const { setDefaultItems } = this.props

        this.fetchData(
            [MenuQuery.getQuery(this._getMenuOptions())],
            ({ menu }) => {
                const reducedMenu = MenuHelper.reduce(menu);
                const categoryArray = Object.values(reducedMenu);
                const [{ children }] = categoryArray;
                setDefaultItems(getSortedItems(Object.values(children)));
            }
        );
    }

    componentDidMount() {
        this._getMenu();
    }

    render() {
        return (
            <MobileMenu
              { ...this.props }
              { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenuContainer);
