/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showPopup } from 'Store/Popup/Popup.action';

import FavorisAppPopup from './FavorisAppPopup.component';
import { FAVORIS_APP_POPUP } from './FavorisAppPopup.config';
import DataContainer from 'Util/Request/DataContainer';

/** @namespace Component/FavorisAppPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    code: state.ConfigReducer.code,
    device: state.ConfigReducer.device
});

/** @namespace Component/FavorisAppPopup/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({
    hidePopup: () => dispatch(showPopup('', {})),
    showPopup: (payload) => dispatch(showPopup(FAVORIS_APP_POPUP, payload))
});

/** @namespace Component/FavorisAppPopup/Container */
export class FavorisAppPopupContainer extends DataContainer {
    static propTypes = {
        hidePopup: PropTypes.func.isRequired,
        code: PropTypes.string
    };

    static defaultProps = {
        code: ''
    };

    render() {
        const { code } = this.props;
        const key = `favoris_${code}`

        return (
            <FavorisAppPopup
              { ...this.props }
              { ...this.state }
              key={ key }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FavorisAppPopupContainer);
