/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import DataContainer from 'Util/Request/DataContainer';
import GtmQuery from 'Query/Gtm.query';
import App from './App.component';

/** @namespace Component/CmsBlock/Container */
export class AppContainer extends DataContainer {
     state = {
         gtmConfig: {}
     };

     containerProps = () => {
         const { gtmConfig } = this.state;
         return { gtmConfig };
     };

     componentDidMount() {
         this._getGTMConfiguration();
     }

     _getGTMConfiguration() {
         this.fetchData(
             [GtmQuery.getGTMConfiguration()],
             (config) => {
                 if (config) {
                     this.setState({
                         gtmConfig: config.gtm
                     });
                 } else {
                     this.setState({
                         gtmConfig: false
                     });
                 }
             }
         );
     }

     render() {
         return (
             <App
               { ...this.containerProps() }
             />
         );
     }
}

export default AppContainer;
