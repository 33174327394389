import {
    RESET_POST,
    UPDATE_POST,
    UPDATE_POST_LOAD_STATUS
} from 'Store/BcsPost/BcsPost.action';

/** @namespace Store/BcsPost/Reducer/getInitialState */
export const getInitialState = () => ({
    post: {},
    isLoading: true
});

/** @namespace Store/BcsPost/Reducer */
export const BcsPostReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_POST:
        const { bcsPost } = action.data;

        return {
            ...state,
            post: bcsPost
        };
    case UPDATE_POST_LOAD_STATUS:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    case RESET_POST:
        return {
            ...state,
            post: {}
        };

    default:
        return state;
    }
};

export default BcsPostReducer;
