/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { lazy, PureComponent, Suspense } from 'react';

import NoMatch from 'Route/NoMatch';

import {
    TYPE_CATALOG,
    TYPE_CATEGORY,
    TYPE_CMS_PAGE,
    TYPE_COLLECTION,
    TYPE_NOTFOUND,
    TYPE_PRODUCT
} from './UrlRewrites.config';
import Loader from 'Component/Loader';

export const ProductPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "product" */ 'Route/ProductPage'));
export const CatalogPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "catalog" */ 'Route/CatalogPage'));
export const CollectionPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Route/CollectionPage'));
export const CategoryPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Route/CategoryPage'));
export const CmsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/CmsPage'));

/**
 * Additional types possible:
 * const TYPE_PWA = 'PWA_ROUTER';
 * const TYPE_CUSTOM = 'CUSTOM';
 * @namespace Route/UrlRewrites/Component
 */
export class UrlRewrites extends PureComponent {
    static propTypes = {
        isNotFound: PropTypes.bool,
        props: PropTypes.object,
        type: PropTypes.string,
        isLoading: PropTypes.bool
    };

    static defaultProps = {
        isNotFound: false,
        props: {},
        type: '',
        isLoading: true
    };

    renderDefaultPage() {
        return (
            <Loader isLoading />
        );
    }

    renderContent() {
        const { props, type, isLoading } = this.props;
        const { id } = props;

        if (!type || isLoading) {
            return this.renderDefaultPage();
        }

        switch (type) {
        case TYPE_PRODUCT:
            return <ProductPage { ...props } key={ id } />;
        case TYPE_CMS_PAGE:
            return <CmsPage { ...props } />;
        case TYPE_CATALOG:
            return <CatalogPage { ...props } />;
        case TYPE_COLLECTION:
            return <CollectionPage { ...props } />;
        case TYPE_CATEGORY:
            return <CategoryPage { ...props } />;
        case TYPE_NOTFOUND:
            return <NoMatch { ...props } />;
        default:
            return this.renderDefaultPage();
        }
    }

    render() {
        const { type, isLoading } = this.props;

        if (!type || isLoading) {
            return this.renderDefaultPage();
        }

        return (
            <Suspense fallback={ this.renderDefaultPage() }>
                { this.renderContent() }
            </Suspense>
        );
    }
}

export default UrlRewrites;
