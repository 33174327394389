/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';
import ShareWishlistForm from 'Component/ShareWishlistForm';

import { SHARE_WISHLIST_POPUP_ID } from './ShareWishlistPopup.config';

import './ShareWishlistPopup.style';

/** @namespace Component/ShareWishlistPopup/Component */
export class ShareWishlistPopup extends PureComponent {
    state = {};

    static propTypes = {
        handleFormData: PropTypes.func.isRequired,
        resetSubmitSuccess: PropTypes.func.isRequired,
        isSubmitSuccess: PropTypes.bool.isRequired
    };

    renderContent() {
        const { handleFormData } = this.props;
        return <ShareWishlistForm onSave={ handleFormData } />;
    }

    render() {
        const {
            isSubmitSuccess,
            resetSubmitSuccess
        } = this.props

        return (
            <Popup
              id={ SHARE_WISHLIST_POPUP_ID }
              clickOutside={ false }
              mix={ { block: 'ShareWishlistPopup', mods: { isSubmitSuccess } } }
            >
                <div block="ShareWishlistPopup" elem="Container">
                    <h3 block="ShareWishlistPopup" elem="Info">{ __('Sharing information') }</h3>
                    { this.renderContent() }
                    <div block="ShareWishlistPopup" elem="Success">
                        { __('Wishlist has been shared') }
                        <button block="Button" onClick={ () => resetSubmitSuccess() }>
                            { __('Close') }
                        </button>
                    </div>
                </div>
            </Popup>
        );
    }
}

export default ShareWishlistPopup;
