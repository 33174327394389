import { connect } from 'react-redux';
import { toggleMenu } from 'Store/MobileMenu/MobileMenu.action';
import BurgerMenu from './BurgerMenu.component'

/** @namespace MessikaFront/Component/Header/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobileMenuOpen: state.MobileMenuReducer.isOpen
});

/** @namespace MessikaFront/Component/Header/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    onBurgerMenuClick: () => dispatch(toggleMenu())
});

export default connect(mapStateToProps, mapDispatchToProps)(BurgerMenu);
