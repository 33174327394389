import {
    UPDATE_PUSH_NOTIFICATION,
    UPDATE_PUSH_NOTIFICATION_LOAD_STATUS
} from 'Store/PushNotification/PushNotification.action';

/** @namespace Store/PushNotification/Reducer/getInitialState */
export const getInitialState = () => ({
    notification: null,
    isLoading: true
});

/** @namespace Store/PushNotification/Reducer */
export const PushNotificationReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_PUSH_NOTIFICATION:
        const { currentNotification } = action.data;

        return {
            ...state,
            notification: currentNotification
        };
    case UPDATE_PUSH_NOTIFICATION_LOAD_STATUS:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };
    default:
        return state;
    }
};

export default PushNotificationReducer;
