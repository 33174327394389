import {
    UPDATE_TAG_LIST,
    UPDATE_TAG_LIST_LOAD_STATUS
} from 'Store/BcsPostTagList/BcsPostTagList.action';

/** @namespace Store/BcsPostList/Reducer/getInitialState */
export const getInitialState = () => ({
    tags: [],
    count: 0,
    isLoading: true
});

/** @namespace Store/BcsPostList/Reducer */
export const BcsPostTagListReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_TAG_LIST:
        const { bcsPostTagList: { items, count } } = action.data;

        return {
            ...state,
            tags: items,
            count
        };
    case UPDATE_TAG_LIST_LOAD_STATUS:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    default:
        return state;
    }
};

export default BcsPostTagListReducer;
