/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import Field from 'Component/Field';
import Form from 'Component/Form';

import { CONTACT_US_DISCLAIMER_CMS_BLOCK } from 'Route/ContactPage/ContactPage.config';

import './FieldForm.style';

/** @namespace Component/FieldForm/Component */
export class FieldForm extends PureComponent {
    onFormSuccess() {
        // TODO: implement
    }

    get fieldMap() {
        return {
            // email: {
            //     label: __('Email'),
            //     validation: ['notEmpty']
            // }
        };
    }

    getDefaultValues([key, props]) {
        const {
            type = 'text',
            onChange = () => {},
            ...otherProps
        } = props;

        return {
            ...otherProps,
            key,
            name: key,
            id: key,
            type,
            onChange
        };
    }

    renderDisclaimerCmsBlock() {
        const {
            contact_us_content: {
                contact_us_disclaimer_block = CONTACT_US_DISCLAIMER_CMS_BLOCK
            } = {}
        } = window.contentConfiguration;

        return <CmsBlock identifier={ contact_us_disclaimer_block } />;
    }

    renderField = (fieldEntry) => (
        <Field { ...this.getDefaultValues(fieldEntry) } />
    );

    renderFields() {
        if (this.useTwoColumns) {
            const fieldLength = (Object.entries(this.fieldMap).length - 1)
            const firstColumnLength = Math.floor(fieldLength / 2) + this?.columnOffset + 1;

            return (
                <>
                    <div
                      block="FieldForm"
                      elem="Fields"
                      mods={ { useTwoColumns: true } }
                    >
                        { Object
                            .entries(this.fieldMap)
                            .slice(0, firstColumnLength)
                            .map(this.renderField) }
                    </div>
                    <div
                      block="FieldForm"
                      elem="Fields"
                      mods={ { useTwoColumns: true } }
                    >

                        { Object
                            .entries(this.fieldMap)
                            .slice(firstColumnLength, fieldLength + 1)
                            .map(this.renderField) }

                        { this.renderCmsBlock && this.renderDisclaimerCmsBlock() }
                    </div>
                </>
            )
        }

        return (
            <div
              block="FieldForm"
              elem="Fields"
            >
                { Object.entries(this.fieldMap).map(this.renderField) }
            </div>
        );
    }

    renderActions() {
        return null;
    }

    render() {
        return (
            <Form
              onSubmitSuccess={ this.onFormSuccess }
              mix={ { block: 'FieldForm', mods: { useTwoColumns: this.useTwoColumns } } }
            >
                <div block="FieldForm" elem="Container" mods={ { useTwoColumns: this.useTwoColumns } }>
                    { this.renderFields() }
                </div>
                { this.renderActions() }
            </Form>
        );
    }
}

export default FieldForm;
