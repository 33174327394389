/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';

export const regionType = PropTypes.oneOfType([
    PropTypes.shape({
        region_code: PropTypes.string,
        region: PropTypes.string,
        region_id: PropTypes.number
    })
]);

export const addressType = PropTypes.shape({
    city: PropTypes.string,
    country_id: PropTypes.string,
    customer_id: PropTypes.number,
    default_billing: PropTypes.bool,
    default_shipping: PropTypes.bool,
    firstname: PropTypes.string,
    id: PropTypes.number,
    lastname: PropTypes.string,
    middlename: PropTypes.string,
    postcode: PropTypes.string,
    prefix: PropTypes.string,
    regionType,
    street: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    suffix: PropTypes.string,
    telephone: PropTypes.string
});

export const addressesType = PropTypes.arrayOf(addressType);

export const customerType = PropTypes.shape({
    addressesType,
    created_at: PropTypes.string,
    default_billing: PropTypes.string,
    default_shipping: PropTypes.string,
    dob: PropTypes.date,
    email: PropTypes.string,
    firstname: PropTypes.string,
    group_id: PropTypes.number,
    id: PropTypes.number,
    is_subscribed: PropTypes.bool,
    lastname: PropTypes.string,
    middlename: PropTypes.string,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    taxvat: PropTypes.string,
    employee_type: PropTypes.string,
    price_display: PropTypes.string,
    remaining_points: PropTypes.number,
    spent_points: PropTypes.number,
    total_points: PropTypes.number,
    wishlist_copy_email: PropTypes.string,
    incentive_store_restriction: PropTypes.bool,
    id_boutique: PropTypes.string
});

export const baseOrderInfoType = PropTypes.shape({
    id: PropTypes.number,
    increment_id: PropTypes.string,
    created_at: PropTypes.string,
    status_label: PropTypes.string,
    grand_total: PropTypes.number,
    subtotal: PropTypes.string
});

// TODO: remove objects
export const orderType = PropTypes.shape({
    base_order_info: baseOrderInfoType,
    order_products: PropTypes.array,
    payment_info: PropTypes.object,
    shipping_info: PropTypes.object
});

export const downloadableType = PropTypes.shape({
    id: PropTypes.number,
    order_id: PropTypes.string,
    status_label: PropTypes.string,
    downloads: PropTypes.string,
    download_url: PropTypes.string,
    created_at: PropTypes.string,
    title: PropTypes.string
});

export const ordersType = PropTypes.arrayOf(orderType);

export const ADDRESS_BOOK = 'address-book';

export const MY_PROFILE = 'my-profile';
export const MY_PROFILE_EDIT = 'edit';
export const MY_PROFILE_PASS = 'password';
export const MY_PROFILE_ADDRESS = 'boutique-address';
export const MY_ORDERS = 'my-orders';
export const USERS = 'users';
export const WARRANTY = 'warranty';
export const ELEARNING = 'e-learning';
export const DOCUMENTATION = 'documentation';
export const INCENTIVE = 'incentive';
export const MERCHANDISING = 'merchandising';
export const CUSTOMER_SERVICE = 'customer-service';
export const PRODUCTS_DETAILS = 'products-details';
export const SIZING = 'sizing';

export const ROLE_SELLER = 'seller';
export const ROLE_MANAGER = 'manager';
export const ROLE_HQ_MANAGER = 'hq_manager';
export const ROLE_AREA_MANAGER = 'area_manager';
export const ROLE_AGENT = 'agent';

export const PARAM_INCENTIVE = 'incentive_store_restriction';

export const PRICE_DISPLAY_PRICE = 'price';
export const PRICE_DISPLAY_NO_PRICE = 'no_price';
export const PRICE_DISPLAY_ON_DEMAND = 'price_on_demand';

export const activeTabType = PropTypes.string;

export const tabType = PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
    headerName: PropTypes.string,
    hideInTabs: PropTypes.bool,
    useWhiteBackground: PropTypes.bool,
    neededRoles: PropTypes.arrayOf(PropTypes.string)
});

export const tabMapType = PropTypes.objectOf(tabType);

export const signInStateType = PropTypes.string;
