import {
    UPDATE_POST_LIST,
    UPDATE_POST_LIST_LOAD_STATUS,
    UPDATE_POST_LIST_PAGE_INDEX,
    UPDATE_POST_LIST_PAGE_SIZE,
    RESET_POST_LIST_PAGE_INDEX, RESET_POST_LIST
} from 'Store/BcsPostList/BcsPostList.action';

/** @namespace Store/BcsPostList/Reducer/getInitialState */
export const getInitialState = () => ({
    posts: [],
    pageSize: 10,
    currentPage: 1,
    count: 0,
    totalCount: 0,
    isLoading: true
});

/** @namespace Store/BcsPostList/Reducer */
export const BcsPostListReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_POST_LIST:
        const { bcsPostList: { items, count, totalCount } } = action.data;

        const posts = [...state.posts, ...items];
        return {
            ...state,
            posts: Array.from(new Set(posts.map(({ id }) => id)), (e) => posts.find(({ id }) => id === e)),
            currentPage: (items && items.length) ? state.currentPage + 1 : state.currentPage,
            count,
            totalCount
        };
    case UPDATE_POST_LIST_LOAD_STATUS:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };
    case UPDATE_POST_LIST_PAGE_INDEX:
        const { currentPage } = action;

        return {
            ...state,
            currentPage
        };
    case RESET_POST_LIST_PAGE_INDEX:
        return {
            ...state,
            currentPage: 1,
            posts: []
        };
    case RESET_POST_LIST:
        return {
            ...getInitialState()
        };
    case UPDATE_POST_LIST_PAGE_SIZE:
        const { pageSize } = action;

        return {
            ...state,
            pageSize
        };

    default:
        return state;
    }
};

export default BcsPostListReducer;
