export const UPDATE_POST_LIST = 'UPDATE_POST_LIST';
export const UPDATE_POST_LIST_LOAD_STATUS = 'UPDATE_POST_LIST_LOAD_STATUS';
export const UPDATE_POST_LIST_PAGE_INDEX = 'UPDATE_POST_LIST_PAGE_INDEX';
export const RESET_POST_LIST_PAGE_INDEX = 'RESET_POST_LIST_PAGE_INDEX';
export const RESET_POST_LIST = 'RESET_POST_LIST';
export const UPDATE_POST_LIST_PAGE_SIZE = 'UPDATE_POST_LIST_PAGE_INDEX';

/** @namespace Store/BcsPostList/Action/updatePostList */
export const updatePostList = (data) => ({
    type: UPDATE_POST_LIST,
    data
});

/**
 * Update loading status
 * @param {Boolean} status Loading indication boolean
 * @return {void}
 * @namespace Store/BcsPostList/Action/updatePostListLoadStatus
 */
export const updatePostListLoadStatus = (status) => ({
    type: UPDATE_POST_LIST_LOAD_STATUS,
    isLoading: status
});

/**
 * Update current page index
 * @param {Number} index Current page indication number
 * @return {void}
 * @namespace Store/BcsPostList/Action/updatePostListPageIndex
 */
export const updatePostListPageIndex = (index) => ({
    type: UPDATE_POST_LIST_PAGE_INDEX,
    currentPage: index
});

/**
 * Update page size
 * @return {void}
 * @namespace Store/BcsPostList/Action/resetPostListPageIndex
 */
export const resetPostListPageIndex = () => ({
    type: RESET_POST_LIST_PAGE_INDEX
});

/**
 * Reset data
 * @return {void}
 * @namespace Store/BcsPostList/Action/resetPostList
 */
export const resetPostList = () => ({
    type: RESET_POST_LIST
});

/**
 * Update page size
 * @param {Number} size Page size indication number
 * @return {void}
 * @namespace Store/BcsPostList/Action/updatePostListPageSize
 */
export const updatePostListPageSize = (size) => ({
    type: UPDATE_POST_LIST_PAGE_SIZE,
    pageSize: size
});
