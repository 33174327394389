import { PureComponent } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { PushNotificationType } from 'Type/PushNotification';

import BrowserDatabase from 'Util/BrowserDatabase';
import { PUSH_NOTIFICATION_KEY } from 'Component/PushNotification/PushNotification.config';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import PushNotification from './PushNotification.component';

export const PushNotificationDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/PushNotification/PushNotification.dispatcher'
);

/** @namespace Component/PushNotification/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isLoading: state.PushNotificationReducer.isLoading,
    notification: state.PushNotificationReducer.notification
});

/** @namespace Component/PushNotification/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({
    requestPushNotification: (options) => PushNotificationDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, options)
    )
});

/** @namespace Component/PushNotification/Container */
export class PushNotificationContainer extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        notification: PushNotificationType,
        requestPushNotification: PropTypes.func.isRequired
    };

    static defaultProps = {
        notification: null
    };

    containerFunctions = {
        handleClose: this.handleClose.bind(this)
    };

    componentDidMount() {
        const {
            requestPushNotification
        } = this.props;

        requestPushNotification();
    }

    containerProps = () => ({
        canDisplay: this.canDisplay()
    });

    handleClose() {
        const {
            notification: {
                id
            }
        } = this.props;

        BrowserDatabase.setItem(id, PUSH_NOTIFICATION_KEY, ONE_MONTH_IN_SECONDS);
    }

    canDisplay() {
        const {
            isLoading,
            notification
        } = this.props;

        return !isLoading && !!notification && this.checkPushNotificationId()
    }

    checkPushNotificationId() {
        const {
            notification: {
                id
            }
        } = this.props;

        const preId = BrowserDatabase.getItem(PUSH_NOTIFICATION_KEY);

        return !preId || parseInt(preId, 10) !== id;
    }

    render() {
        return (
            <PushNotification
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PushNotificationContainer);
