export const SELECTION_CODE = 'specials_custom';
export const SELECTION_NEW = 0;
export const SELECTION_NEWS_FROM_CODE = 'news_from_date_bucket';
export const SELECTION_NEWS_TO_CODE = 'news_to_date_bucket';

export const SELECTION_ATTRIBUTES = [{
    code: 'isIconique',
    label: __('iconicProduct')
}, {
    code: 'is_finejo',
    label: __('fineJewelryProduct')
}];
